<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1>Sub Providers</h1>
        <b-button variant="primary" :to="{ name: 'subprovider-detail', params: { id: 'new' } }">
          Create new Sub Provider
        </b-button>
      </b-col>

      <b-col cols="4" class="text-right">
        <label for="text-search" class="mr-4">Search</label>
        <b-form-input id="text-search" class="inline mr-4" v-model="filter.q" debounce="800" />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <rest-table
            endpoint="sub-providers"
            :fields="fields"
            :filter="filter">

          <template v-slot:cell(actions)="data">
            <router-link :to="{ name: 'subprovider-detail', params: { id: data.item.SubProviderID } }">
              <b-icon-pencil-square></b-icon-pencil-square>
            </router-link>
          </template>
        </rest-table>
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  export default {
    name: 'SubProviders',
    components: {
      'rest-table': () => import('@/components/RestTable.vue'),
    },
    data () {
      return {
        filter: { q: '' },
        fields: [
          {
            key: 'Description',
            label: 'Description',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'link-icons',
          },
        ],
      }
    },
    mounted () {
    }
  }
</script>

<style scoped>

</style>